import { useSelector } from 'react-redux';
import { IAuthUser } from '../store';
import { IState } from '../store/IState';

interface IReturnAuth {
  user: IAuthUser | any;
  isAuthenticated: boolean;
  authenticating: boolean;
}

export const useAuth = (): IReturnAuth => {
  const { user, authenticating } = useSelector(
    (state: IState) => state.flint.auth,
  );
  const isAuthenticated = !!(user && Object.keys(user).length);
  return {
    user,
    authenticating,
    isAuthenticated,
  };
};

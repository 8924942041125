import * as React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    zIndex: 9,
  },
  text: {
    marginTop: 20,
  },
  hide: {
    display: 'none',
  },
}));

interface AppProgressProps {
  loading: boolean;
  logo?: any;
}
export const AppProgress = ({ loading, logo }: AppProgressProps) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(classes.root, {
        [classes.hide]: !loading,
      })}
    >
      <CircularProgress />
      <h1 className={classes.text}>{t('flint:please wait')}</h1>
      {logo ? (
        <img src={logo} alt="logo" style={{ marginTop: 20, width: 200 }} />
      ) : null}
    </div>
  );
};

import { withStyles, Button } from '@material-ui/core';

export const MainButton = withStyles({
  root: {
    borderRadius: 100,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
})(Button);

import { makeStyles, createStyles } from '@material-ui/core'

export const useStyle = makeStyles((theme) =>
  createStyles({
    root: {},
    paper: {
      minWidth: theme.spacing(448 / 8),
      padding: theme.spacing(49 / 8, 20 / 8),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    heading: {
      fontWeight: 800,
      fontSize: theme.spacing(2),
      color: '#252527',
    },
    description: {
      fontSize: theme.spacing(1.8),
      color: '#7c7c7c',
      marginBottom: theme.spacing(40 / 8),
    },
    content: {},
    button: {
      fontWeight: 'bold',
      padding: theme.spacing(2),
      marginTop: theme.spacing(5),
      '&:hover': {
        backgroundColor: '#5cb89dc4',
      },
    },
  })
)

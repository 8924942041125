import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      borderLeftWidth: 5,
      borderLeftColor: 'transparent',
      borderLeftStyle: 'solid',
      position: 'relative',
      minHeight: 50,
      '&:hover': {
        backgroundColor: '#f8f8f8',
        '& .hide_menu_text': {
          display: 'block',
        },
      },
    },
    sideIcon: {
      display: 'block',
      textAlign: 'center',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },

    subItem: {
      color: '#a6a5a9',
      fontSize: 14,
    },
    rootText: {
      fontSize: 16,
      color: '#a6a5a9',
      fontWeight: 500,
    },
    activeLink: {
      fontWeight: 'bold',
      color: '#16151a',
    },

    rootActive: {
      borderLeftColor: theme.palette.secondary.main,
    },
    hideRootText: {
      display: 'none',
    },
    hide: {
      display: 'none',
    },
    icon: {
      fill: '#a6a5a9!important',
      width: 30,
      height: 30,
    },
    activeIcon: {
      fill: '#000!important',
    },
  }),
);



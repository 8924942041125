import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
  root: {},
  heading: {
    backgroundColor: theme.palette.common.white,
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.common.black,
    borderRight: '1px solid',
    borderRightColor: '#e1deed',
    '&:last-of-type': {
      borderTopRightRadius: 50,
      borderRight: 'none',
    }


  },
  cell: {
    borderRight: '1px solid',
    borderRightColor: '#e1deed',
    color: '#7c7c7c',
    '&:last-of-type': {
      borderRight: 'none'
    }
  }
}))
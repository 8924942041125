import React from 'react';
import { useStyle } from './Toolbar.style';
import clsx from 'clsx';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IState } from '../../store/IState';
import { DrawerDetailsIcon } from '../DrawerDetailsIcon';
import { ToolbarIcons } from '../ToolbarIcons';
import { useTranslation } from 'react-i18next';
import { PAGE_TITLE_POS } from '../../store';

export const Toolbar = ({ pgTitlePos }: { pgTitlePos?: PAGE_TITLE_POS }) => {
  const classes = useStyle();
  const {
    isDrawerOpen,
    hasDetailsWrapper,
    isDetailsWrapperOpen,
    toolbarAdditional,
    hasToolbarIcons,
    pageTitle,
    pageSubTitle,
    pageTitlePos,
  } = useSelector((state: IState) => state.flint.layout);
  const { t } = useTranslation();

  const [isStyleApplied, applayGoogleStyle] = React.useState(false);
  let titlePos = pgTitlePos || pageTitlePos;

  const renderPageTitle = (
    <Typography variant="h5" className={classes.pageTitle}>
      {pageTitle && t(pageTitle)}
    </Typography>
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.appBarShift]: isDrawerOpen,
        [classes.shiftFromLeft]: isDetailsWrapperOpen,
      })}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid>
          {/*  render page tilte on top of page sub title base on sent props */}
          {titlePos === PAGE_TITLE_POS.TOP ? renderPageTitle : null}
          <Typography variant="h6" className={classes.platformTitle}>
            {pageSubTitle && t(pageSubTitle)}
          </Typography>
          {/*  render page tilte below of page sub title base on sent props */}
          {titlePos === PAGE_TITLE_POS.BOTTOM ? renderPageTitle : null}
        </Grid>

        <div
          className={clsx({ [classes.googleInputStyle]: isStyleApplied })}
          style={{ paddingLeft: isDetailsWrapperOpen ? 0 : 30 }}
        >
          {hasToolbarIcons && (
            <ToolbarIcons applayGoogleStyle={applayGoogleStyle} />
          )}
          {toolbarAdditional || null}
        </div>
      </Grid>
      {hasDetailsWrapper ? <DrawerDetailsIcon /> : null}
    </div>
  );
};

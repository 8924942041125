import { makeStyles } from '@material-ui/core';
import React from 'react';
import { CardTableCol } from '../CardTableCol';
import { CardTableRowProps } from './CardTableRow.types';

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    marginLeft: 10,
    borderBottom: 'solid 1px #dcdcdc',
    '&:last-of-type': {
      border: 'none',
    },
  },
}));

export const CardTableRow = ({ isHeader, row = [] }: CardTableRowProps) => {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      {row.map((col, index) => (
        <CardTableCol
          isHeader={isHeader}
          col={col}
          isFirstCol
          index={index}
          key={index}
        />
      ))}
    </div>
  );
};

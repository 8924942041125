import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { IReducerStateAction } from './IState';
import { mapInitialState } from './map';
import { authInitialState } from './auth';
import { layoutInitialState } from './layout';

export const getReducerState = (
  reducerName: string,
  state: any,
  { reducer, type, payload }: IReducerStateAction,
  prefix: string,
) => {
  prefix = prefix ? `${prefix}.` : '';
  if (`${prefix}${reducerName}` === reducer) {
    return {
      ...state,
      [type]: payload,
    };
  }

  return state;
};

export const getMappedReducers = ({
  reducers,
  prefix,
}: {
  reducers: any;
  prefix?: string;
}) =>
  Object.entries(reducers).reduce(
    (data: any, [reducerName, getState]: any) => ({
      ...data,
      [reducerName]: (
        initialState = getState(),
        payload: IReducerStateAction,
      ) => getReducerState(reducerName, initialState, payload, prefix),
    }),
    {},
  );

export const flintReducers = {
  flint: combineReducers({
    ...getMappedReducers({
      prefix: 'flint',
      reducers: {
        map: mapInitialState,
        auth: authInitialState,
        layout: layoutInitialState,
      },
    }),
  }),
};

export const flintCombinedReducer = combineReducers(flintReducers);

export const flintStore = createStore(
  flintCombinedReducer,
  applyMiddleware(ReduxThunk),
);

/**
 * Dispatch more than one value at the same time
 * ( i.e ) { layout: { title: 'Hello', subTitle: 'I\'m a subtitle!' }, user: { name: 'Haytham' }}
 * 
 * @param data 
 */
 export const dispatcher = (data: object, store?: any) => {
  const promises = Object.entries(data).reduce((promises, [reducer, dispatshed]) => {
   const reducerPromises = Object.entries(dispatshed).map(([type, payload]) => (store || flintStore).dispatch({
     type,
     payload,
     reducer,
   }))

   return promises.concat(reducerPromises)
 }, [])

 return Promise.all(promises)
}

import { ThemeOptions } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const DRAWER_WIDTH = 308;
export const LEFT_LIST_WIDTH = 665;

// Create a theme instance.
export const flintTheme: ThemeOptions = {
  direction: 'rtl',
  typography: {
    fontFamily: 'Almarai, sans-serif !important',
  },
  palette: {
    primary: {
      main: '#005f96',
    },
    secondary: {
      main: '#015F96',
    },

    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
};

import { createStyles, makeStyles } from '@material-ui/core';
import { LEFT_LIST_WIDTH } from '../../utils/theme';

export const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#f6f5fa',
      minHeight: 500,
      padding: theme.spacing(30 / 8),
      position: 'fixed',
      right: -LEFT_LIST_WIDTH,
      height: '100vh',
      paddingBottom: 100,
      top: 0,
      width: 0,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    listIsOpen: {
      right: 0,
      width: theme.spacing(LEFT_LIST_WIDTH / 8),
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    contentWrapper: {
      overflowY: 'auto',
      height: '100%',
      paddingBottom: 100,
      padding: theme.spacing(0, 1.5),
      overflowX: 'hidden',
    },

    clusteredMsgWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    hide: {
      display: 'none',
    },
  }),
);

import { useSelector } from "react-redux";

import { IState } from "../../store/IState";

interface IReturn {
  maps: any;
  map: any;
}
export const useGoogleMaps = (): IReturn => {
  const { mapInstance, googleMaps } = useSelector(
    (state: IState) => state.flint.map
  );

  return {
    maps: googleMaps,
    map: mapInstance,
  };
};

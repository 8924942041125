import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.5),
    borderBottom: '1px sold #ccc',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.common.black,
    borderRight: '1px solid #e3e3e3',
    '&:last-of-type': {
      borderRight: 'none',
    },
  },
  headerRestCol: {
    fontSize: 13,
    color: '#7c7c7c',
    fontWeight: 'normal',
    marginTop: 1,
  },
  firstCol: {
    minWidth: 200,
    '&:last-of-type': {
      flexGrow: 1,
    },
  },
  titleCol: {
    borderBottom: '1px solid #f4f4f4',
    margin: theme.spacing(0, 1),
    borderRight: 'none',
  },
  titleColAndHeader: {
    padding: theme.spacing(1),
    fontSize: 18,
  },
}));

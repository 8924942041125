import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      justifyContent: 'flex-start'
    },
    center: {
      alignItems: 'center'
    },
    dropdownClosedButton: {
      height: 65,
      width: '100%',
      borderRadius: 10,
      backgroundColor: '#f8f8f8',
      justifyContent: 'flex-start',
      border: '1px solid #f8f8f8',
      '&:hover': {
        backgroundColor: '#f8f8f8',
      },
    },
    guestDropdownBtn: {
      height: 65,
      borderRadius: '10px !important',
      fontWeight: 'bold !important',
    },
    dropdownOpenButton: {
      height: 65,
      width: '100%',
      minWidth: 235,
      borderRadius: 10,
      backgroundColor: '#fff',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      border: '1px solid #dfdfdf',
      justifyContent: 'flex-start',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
      '&:hover': {
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
      },
    },
    dropdownBtnContent: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dropdownContainer: {
      width: '100%',
      position: 'relative',
    },
    dropdownPopper: {
      left: -1,
      zIndex: 1,
      width: '100%',
      borderTop: 'none',
      overflow: 'hidden',
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: '#fff',
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      border: '1px solid #dfdfdf',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.08)',
    },
    openDrawerDropdownPopper: {
      right: 0,
      left: 'auto',
      minWidth: 235,
    },
    dropdownMenuItem: {
      paddingTop: 12,
      paddingBottom: 12,
      '&:not(:last-child)': {
        borderBottom: '1px solid #dfdfdf',
      },
    },
    dropdownMenuItemIcon: {
      width: 15,
      height: 15,
    },
    dropdownMenuItemText: {
      fontSize: 14,
      marginLeft: 10,
    },
    profileUserIcon: {
      width: 50,
      padding: 0,
      height: 50,
      minWidth: 50,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    guestUserBtnIcon: {
      width: 20,
      height: 20,
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  }),
);

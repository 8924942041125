import createUploadLink from 'apollo-upload-client/public/createUploadLink';
import { customHttpFetch } from '../customHttpFetch';
import { GqConfig } from '../GqConfig';

export const httpLink = () => {
  const uri = GqConfig.getOne('url');

  return createUploadLink({
    uri,
    fetch: customHttpFetch,
  });
};

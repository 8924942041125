import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { DRAWER_WIDTH } from '../../utils/theme';

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerContent: {
      overflowX: 'hidden',
      // padding: theme.spacing(0, 3.75),
    },
    drawerContentClosed: {
      overflowX: 'visible',
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
      display: 'flex',
      justifyContent: 'space-between',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // overflowX: 'hidden',
      width: theme.spacing(11),
      justifyContent: 'space-between',
    },
    drawerPaper: {
      overflow: 'unset',
    },
    footer: {
      // backgroundColor: '#f0f0f0',
      padding: theme.spacing(0, 2),
      paddingBottom: theme.spacing(3),
    },
    footerList: {
      display: 'flex',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      justifyContent: 'center',
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    footerListItem: {
      fontSize: 11,
      fontWeight: 'bold',
      color: theme.palette.common.black,
      '&::after': {
        content: '"-"',
        margin: theme.spacing(0, 0.5),
      },
      '&:last-of-type': {
        '&::after': {
          display: ' none',
        },
      },
    },
    hide: {
      display: 'none',
    },
    copyright: {
      fontSize: 13,
      color: '#7c7c7c',
      textAlign: 'center',
      marginTop: theme.spacing(1.5),
    },
    divider: {
      borderBottom: '1px dashed #dfdfdf',
      width: '80%',
      margin: theme.spacing(2, 'auto'),
    },
    menuIcon: {
      borderRadius: '50%',
      padding: 0,
      minWidth: 'auto',
      width: 41,
      height: 41,
      margin: 'auto',
      display: 'block',
    },
  }),
);



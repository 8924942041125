import * as React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const RightToLeft = ({ children }: { children: any }) => {
  return <StylesProvider jss={jss}>{children}</StylesProvider>;
};

// props validation
RightToLeft.propTypes = {
  children: PropTypes.element.isRequired,
};
export default RightToLeft;

import * as React from 'react';
import clsx from 'clsx';
import { Drawer } from '../Drawer';
import { Toolbar } from '../Toolbar';
import { useStyle } from './Layout.style';
import { LayoutProps } from './Layout.types';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../store/IState';
import { authenticateUserAsync } from '../../store';
import { useEffect } from 'react';
import { useAuth } from '../../hooks';
import { CircularProgress } from '@material-ui/core';
import { AppProgress } from '../AppProgress';

const OverlayLoader = () => {
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
      <h1>برجاء الإنتظار...</h1>
    </div>
  );
};
export const Layout: React.FC<LayoutProps> = ({
  children,
  requiredAuth = true,
  pageSubTitle,
  pageTitle,
  disableDrawer,
  disableToolbar,
  enableDetailsView,
  openDetailsView,
  toolbarAdornment,
  secondaryDrawerEnabled,
  pageTitlePos,
}: LayoutProps) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { layout } = useSelector((state: IState) => state.flint);
  const { isAuthenticated, user, authenticating } = useAuth();
  const { isDrawerOpen: drawerIsOpen, isDetailsWrapperOpen } = layout;

  // authenticate user
  useEffect(() => {
    if (!isAuthenticated && !authenticating) {
      dispatch(authenticateUserAsync(requiredAuth));
    }
  }, [isAuthenticated, user]);

  // set up layout config
  // set redux per layout/page
  useEffect(() => {
    if (pageTitle) {
      dispatch({
        reducer: 'flint.layout',
        type: 'pageTitle',
        payload: pageTitle,
      });
    }
    if (pageSubTitle) {
      dispatch({
        reducer: 'flint.layout',
        type: 'pageSubTitle',
        payload: pageSubTitle,
      });
    }

      dispatch({
        reducer: 'flint.layout',
        type: 'hasDetailsWrapper',
        payload: enableDetailsView,
      });

      dispatch({
        reducer: 'flint.layout',
        type: 'isDetailsWrapperOpen',
        payload: enableDetailsView && openDetailsView,
      });
    if (typeof toolbarAdornment !== 'undefined') {
      dispatch({
        reducer: 'flint.layout',
        type: 'toolbarAdditional',
        payload: toolbarAdornment,
      });
    }
    if (secondaryDrawerEnabled) {
      dispatch({
        reducer: 'flint.layout',
        type: 'secondaryDrawerEnabled',
        payload: true,
      });
    }
    // reset reduxs
    return () => {
      if (pageTitle) {
        dispatch({
          reducer: 'flint.layout',
          type: 'pageTitle',
          payload: undefined,
        });
      }
      if (pageSubTitle) {
        dispatch({
          reducer: 'flint.layout',
          type: 'pageTitle',
          payload: undefined,
        });
      }
      if (typeof enableDetailsView !== 'undefined') {
        dispatch({
          reducer: 'flint.layout',
          type: 'hasDetailsWrapper',
          payload: false,
        });

        dispatch({
          reducer: 'flint.layout',
          type: 'isDetailsWrapperOpen',
          payload: false,
        });
      }
      if (typeof toolbarAdornment !== 'undefined') {
        dispatch({
          reducer: 'flint.layout',
          type: 'toolbarAdditional',
          payload: undefined,
        });
      }
      if (secondaryDrawerEnabled) {
        dispatch({
          reducer: 'flint.layout',
          type: 'secondaryDrawerEnabled',
          payload: false,
        });
      }
    };
  }, []);

  if (authenticating) {
    return <AppProgress loading />;
  }

  // DOM RENDER
  return (
    <div className="flint__layout">
      {disableToolbar ? null : <Toolbar pgTitlePos={pageTitlePos} />}
      {disableDrawer ? null : <Drawer />}
      <main
        className={clsx(classes.content, {
          [classes.drawerClosed]: !drawerIsOpen,
          [classes.shiftRight]: isDetailsWrapperOpen,
          [classes.hasLayoutDrawer]: !disableDrawer,
          [classes.hasToolbar]: !disableToolbar,
        })}
      >
        {children}
      </main>
    </div>
  );
};

Layout.defaultProps = {
  requiredAuth: true,
  enableDetailsView: false,
  openDetailsView: true,
  disableDrawer: false,
  disableToolbar: false,
  secondaryDrawerEnabled: false,
};

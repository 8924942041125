import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
export * from 'react-i18next';
import ar from './ar.json';
import en from './en.json';
// the translations
// (tip move them in a JSON file and import them)
export const setupLocalization = (resources: {ar: ResourceLanguage;en: ResourceLanguage;} = { ar: {}, en: {} }) => {
  const flintResources = {
    en: {
      flint: en,
      ...resources.en,
    },
    ar: {
      flint: ar,
      ...resources.ar,
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: flintResources,
      lng: 'ar',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export const flintI18n = i18n;

import React, { useEffect, useState, useCallback } from 'react';
import { FlintProviderProps } from './FlintProvider.types';
import '../../assets/scss/main.scss';
import { flintReducers, flintStore } from '../../store';
import { FlintWrapper } from './FlintWrapper';
import { GqConfig } from '../../graphql';
import { AppProgress } from '../AppProgress';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { FlintAppWrapper } from './FlintAppWrapper';

export const FlintProvider = (props: FlintProviderProps) => {
  const [providerIsReady, setProviderIsReady] = useState(false);
  // Redux Store
  const store = props.store
    ? props.store
    : props.reducers
    ? combineReducers({ ...props.reducers, ...flintReducers })
    : flintStore;

  const graphQlConfig = (props.config || {}).graphql;

  const AppWrapper = useCallback(
    (props: any) => {
      if (graphQlConfig && Object.keys(graphQlConfig).length) {
        return <FlintAppWrapper {...props} />;
      }

      return <div>{props.children}</div>;
    },
    [graphQlConfig],
  );

  useEffect(() => {
    const promises = [];

    // GraphQl
    if (graphQlConfig && Object.keys(graphQlConfig).length) {
      console.log(`[SETUP GraphQl CONFIG]`, graphQlConfig);
      promises.push(GqConfig.setupConfig(graphQlConfig));
    }

    Promise.all(promises).then(() => {
      setProviderIsReady(true);
    });
  }, []);

  if (!providerIsReady) return <AppProgress loading />;

  // Render DOM
  return (
    <AppWrapper>
      <Provider store={store}>
        <FlintWrapper {...props} />
      </Provider>
    </AppWrapper>
  );
};

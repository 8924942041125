import {
  gql,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  OperationVariables,
  MutationHookOptions,
} from '@apollo/client'
import { authLink, httpLink, networkErrorLink } from './links'

export const createClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache(),
    // link: authLink.concat(httpLink),
    link: ApolloLink.from([authLink, networkErrorLink, httpLink()]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
  })
}

var GraphQlClientInstance: any = null

export const getGraphqlClient = () => {
  if (!GraphQlClientInstance) {
    GraphQlClientInstance = createClient()
  }

  return GraphQlClientInstance
}

export interface IGraphQlQueryProps {
  variables?: OperationVariables
  query: MutationHookOptions<any, OperationVariables>
}

// this is a promise to query data
export const graphQlQuery = ({ query, variables }: IGraphQlQueryProps) => {
  return getGraphqlClient().query({
    query: gql`
      ${query}
    `,
    variables,
  })
}

export interface IGraphQlMutateProps {
  variables: OperationVariables
  mutation: MutationHookOptions<any, OperationVariables>
}

// this is a promise to mutate data
export const graphQlMutate = ({ mutation, variables }: IGraphQlMutateProps) => {
  return getGraphqlClient().mutate({
    mutation,
    variables,
  })
}

interface IFinalMutationProps {
  enums?: object
  mutation: string
  variables?: object
  gql?: boolean
}

export const graphQlModifiedMutation = ({
  enums,
  mutation,
  gql: returnGql,
}: IFinalMutationProps) => {
  const finalMutation = Object.entries(enums || {}).reduce((txt, [key, value]) => txt.replace(new RegExp(key, 'g'), value), mutation)

  return returnGql ? gql`${finalMutation}` : finalMutation
}

import uid from 'uid-safe';
import jwt from 'jsonwebtoken';
import cookies from 'js-cookie';
import clientOauth2 from 'client-oauth2';

import { AxiosRequestConfig } from 'axios';
import { getUserInfo, refreshUserToken } from './authApi';
import JwkClient from './jwkClient';
import { AuthConfig } from './AuthConfig';
import { strToCamelCase } from '../utils';

/**
 * Auth storage keys.
 *
 * @var array
 */
const authStorageKeys = [
  'token_type',
  'expires_in',
  'access_token',
  'refresh_token',
];

/**
 * Get auth client.
 *
 */
export const getAuthClient = (options = {}) => {
  return new clientOauth2({
    state: uid.sync(18),
    clientId: AuthConfig.getOne('clientId'),
    scopes: AuthConfig.getScopes(),
    redirectUri: AuthConfig.getOne('redirectUri'),
    accessTokenUri:
      AuthConfig.getOne('domain') + AuthConfig.getOne('tokenPath'),
    authorizationUri:
      AuthConfig.getOne('domain') + AuthConfig.getOne('authorizePath'),
    ...options,
  });
};

/**
 * Get storage item promise.
 *
 * @return Promise
 */
export const getStorageItem = (key) => Promise.resolve(cookies.get(key));

/**
 * Get auth token.
 *
 * @return string
 */
export const getAuthToken = () => getStorageItem('access_token');

/**
 * Auth interceptor.
 *
 * @return AxiosRequestConfig
 */
export const authInterceptor = async (config: AxiosRequestConfig) => {
  /**
   * Set Authorization header to request.
   *
   */
  const token = await getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

/**
 * Get redirect URL.
 *
 * @return string
 */
export const getRedirectUrl = () => {
  const { code, options } = getAuthClient();

  cookies.set('auth_state', options.state);

  return code.getUri();
};

/**
 * Save user authentication data in storage.
 *
 */
export const saveUserAuthenticationData = (data) => {
  authStorageKeys.forEach((key) => {
    const camelCaseKey = strToCamelCase(key);
    cookies.set(key, data[camelCaseKey]);
  });
};

/**
 * Logout user.
 *
 */
export const logoutUser = () => {
  authStorageKeys.forEach((key) => cookies.remove(key));

  location.href = '/';
};

/**
 * Refresh user token
 *
 */
export const refreshToken = async () => {
  try {
    const { data } = await refreshUserToken(cookies.get('refresh_token'));
    saveUserAuthenticationData(data);

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Get RSA key.
 *
 */
export const getKey = ({ kid }, callback) => {
  const jwkClient = new JwkClient();

  jwkClient
    .getSigningKey(kid)
    .then(({ publicKey, rsaPublicKey }) => {
      callback(null, publicKey || rsaPublicKey);
    })
    .catch((error) => {
      callback(error, null);
    });
};

/**
 * Verify the token by comparing the RSA with the token.
 *
 */
export const verifyToken = (token) => {
  if (!token) {
    return Promise.reject('token undefined');
  }

  return new Promise((resolve, reject) => {
    jwt.verify(token, getKey, function (err, decoded) {
      if (err) return reject(err);
      return resolve(decoded);
    });
  });
};

/**
 * Check user authentication.
 *
 */
export const authCheck = async (token) => {
  // check if there authenticated, if token exits in the cookie object.
  if (!token) {
    return {
      unauthenticated: true,
    };
  }

  try {
    // verify token
    const decoded = await verifyToken(token);
    // --- decode user info if it is logged in successfully
    return {
      token,
      user: decoded.ext,
      authenticated: true,
    };

    // check the status if error, whether the token expired or invalid
  } catch (error) {
    if (error && error.name && error.name === 'TokenExpiredError') {
      return {
        expired: true,
      };
    }

    return {
      invalid: true,
    };
  }
};

/**
 * Authenticate user.
 *
 */
export const authenticateUser = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await authCheck(cookies.get('access_token'));
      console.log(`[AuthUtils][authenticateUser]`, data);
      const { authenticated, expired, user, unauthenticated, invalid } = data;

      if (expired) {
        const { accessToken } = await refreshToken();
        const { user } = await authCheck(accessToken);

        resolve({ user });
      } else if (authenticated) {
        resolve({ user });
      } else if (unauthenticated || invalid) {
        reject({
          authorize: () => {
            location.href = getRedirectUrl();
          },
        });
      }
    } catch (error) {
      reject({
        error,
        authorize: () => {
          location.href = getRedirectUrl();
        },
      });
    }
  });
};

/**
 * Authenticate user when redirect back.
 *
 */
export const authenticateUserBack = () => {
  const { code } = getAuthClient({
    state: cookies.get('auth_state'),
  });

  return new Promise((resolve, reject) => {
    code
      .getToken(location.href)
      .then(({ data, accessToken }) => {
        const coppiedData = {};
        console.log('[authenticateUserBack] data before transformed', data);
        Object.keys(data).forEach((key) => {
          coppiedData[strToCamelCase(key)] = data[key];
        });
        console.log(
          '[authenticateUserBack] data after transformed',
          coppiedData,
        );
        saveUserAuthenticationData(coppiedData);

        verifyToken(accessToken).then(() => {
          getUserInfo()
            .then(({ data }) => {
              resolve(data);
            })
            .catch(() => {
              authenticateUser()
                .then((data) => {
                  resolve(data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
        });
      })
      .catch((error) => {
        console.log(`[authenticateUserBack] ERROR`, error);
        authenticateUser()
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
  });
};

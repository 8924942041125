import * as React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useStyle } from './DetailsWrapper.style';
import AlertMessage from '../AlertMessage/AlertMessage';
import { DetailsWrapperProps } from './DetailsWrapper.interface';
import LoadingProgress from '../LoadingProgress/LoadingProgress';
import { DetailsWrapperHeader } from './DetailsWrapperHeader/DetailsWrapperHeader';
import { IState } from '../../store/IState';

export const DetailsWrapper: React.FC<DetailsWrapperProps> = ({
  isLoading,
  isDataLength = false,
  message = undefined,
  text = undefined,
  header = {},
  children,
}: DetailsWrapperProps) => {
  const { isDetailsWrapperOpen } = useSelector(
    (state: IState) => state.flint.layout,
  );

  const classes = useStyle();

  return (
    <div
      id="flint_details-wrapper-root"
      className={clsx(classes.root, {
        [classes.listIsOpen]: isDetailsWrapperOpen,
      })}
    >
      <LoadingProgress loading={isLoading} />
      <DetailsWrapperHeader {...header} />
      <div
        className={classes.contentWrapper}
        id="flint_details-wrapper-content"
      >
        <div
          className={clsx(classes.clusteredMsgWrapper, {
            [classes.hide]: isDataLength,
          })}
        >
          <AlertMessage hide={isLoading} message={message} text={text} />
        </div>
        <div
          id="flint_details-wrapper-body"
          className={clsx({
            [classes.hide]: !isDataLength,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

DetailsWrapper.defaultProps = {
  isLoading: false,
  isDataLength: false,
};

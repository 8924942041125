import * as React from 'react';
import clsx from 'clsx';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WarningIcon } from '../../assets/images/icons/warning_icon.svg';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 250,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    width: '100%',
    textAlign: 'center',
  },
  text: {
    fontWeight: 'bold',
    letterSpacing: 0,
    fontSize: 17,
    marginTop: 30,
  },
  hide: {
    display: 'none',
  },
}));

interface IProps {
  hide?: boolean;
  message?: string | undefined;
  text?: string | undefined;
}

export const AlertMessage = ({ hide = false, message,text }: IProps) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Paper
      className={clsx(classes.root, {
        [classes.hide]: hide,
      })}
      elevation={0}
    >
      <WarningIcon />
      {message && <Typography className={classes.text}>{t(message)}</Typography>}
      {text && <Typography className={classes.text}>{t(text)}</Typography>}
    </Paper>
  );
};

export default AlertMessage;

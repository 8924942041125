// @ts-nocheck
import { Button, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { IState } from '../../store/IState';
import { useDispatch, useSelector } from 'react-redux';
import DrawerIcon from '../../assets/images/icons/drawer_icons/drawer_icon.png';
import { AnchorHTMLAttributes } from 'react';
import { ReactComponentElement } from 'react';

const useStyle = makeStyles((theme) => ({
  root: {
    lineHeight: 0.5,
    overflow: 'hidden',
    padding: theme.spacing(2, 2),
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    borderBottomStyle: 'solid',
    height: 'auto',
  },
  col: {
    display: 'flex',
    flexDirection: 'column-reverse',

  },
  slogan: {
    fontWeight: 'bold',
    fontSize: 10,
    margin: 0,
    color: '#252527',
  },
  hide: {
    display: 'none',
  },
  menuIcon: {
    borderRadius: '50%',
    padding: 0,
    minWidth: 'auto',
    width: 41,
    height: 41,
    margin: 'auto',
    display: 'block',
  },
  logo: {
    '& img': {
      maxWidth: '100%',
      maxHeight: 65,
    },
    '& svg': {
      maxWidth: '100%',
      maxHeight: 65,
    },
  },
}));

interface DrawerToolbarProps<T> {
  Component: React.FunctionComponent<any> | React.ReactElement;
  compnentProps: T;
}

export const DrawerToolbar = ({
  Component,
  compnentProps = { href: '/' },
}: DrawerToolbarProps<AnchorHTMLAttributes<string>>) => {
  const classes = useStyle();
  const { drawerToolbar, isDrawerOpen } = useSelector(
    (state: IState) => state.flint.layout,
  );
  const dispatch = useDispatch();
  const { LogoIcon, FullLogo } = drawerToolbar;

  const updateDrawerStatus = () => {
    dispatch({
      reducer: 'flint.layout',
      type: 'isDrawerOpen',
      payload: !isDrawerOpen,
    });
  };

  return (
    <Grid
      container
      className={clsx({[classes.root]: true, [classes.col]: !isDrawerOpen})}
      justify="space-between"
      wrap="nowrap"
      alignItems="center"
    >
      <Grid item>
        <Component {...compnentProps}>
          <Grid container style={{ cursor: 'pointer' }} alignItems="center">
            {/* Logo Icon when drawer is closed */}
            <Grid
              className={clsx({
                [classes.hide]: isDrawerOpen,
              })}
            >
              <div className={classes.logo}>{LogoIcon && LogoIcon}</div>
            </Grid>
            {/* Full Logo when drawer is open */}
            <Grid
              item
              className={clsx({
                [classes.hide]: !isDrawerOpen,
              })}
            >
              <div className={classes.logo}>{FullLogo && FullLogo}</div>
            </Grid>
          </Grid>
        </Component>
      </Grid>
      <Grid
        item
      >
        <Button className={classes.menuIcon} onClick={updateDrawerStatus}>
          <img src={DrawerIcon} alt="drawer icon" />
        </Button>
      </Grid>
    </Grid>
  );
};

import { createStyles, makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      width: 27,
      height: 27,
      boxShadow: theme.shadows[1],
      borderRadius: '50%',
      padding: 0,
      minWidth: 'auto',
      transform: 'translateX(calc(-30px + -50%))',
      zIndex: 2,
      '&:hover': {
        backgroundColor: '#f1f1f1',
      },
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    closed: {
      transform: 'translateX(0)',
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  }),
);

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { LEFT_LIST_WIDTH, DRAWER_WIDTH } from '../../utils/theme';

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottomWidth: 1,
      borderBottomColor: '#efefef',
      borderBottomStyle: 'solid',
      height: 83,
      padding: theme.spacing(0, 3.75),
      marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 1,
      },
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },

    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    shiftFromLeft: {
      marginRight: LEFT_LIST_WIDTH,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    searchIcon: {
      '&:hover': {
        background: 'transparent',
      },
    },
    platformTitle: {
      fontSize: 14,
      color: '#16151a',
    },
    pageTitle: {
      fontSize: 28,
      fontWeight: 'bold',
      color: '#252527',
    },
    googleInputStyle: {
      position: 'absolute',
      top: 13.5,
      right: 0,
    },
  }),
);

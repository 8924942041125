import { flintTheme } from '../../utils';
import { ILayoutState, PAGE_TITLE_POS } from './LayoutState';

export const layoutInitialState = (): ILayoutState => ({
  drawerToolbar: {},
  pageTitle: undefined,
  pageSubTitle: undefined,
  isDrawerOpen: true,
  links: [],
  currentUrlPath: '/',
  isDetailsWrapperOpen: false,
  hasDetailsWrapper: false,
  hasToolbarIcons: false,
  toolbarAdditional: null,
  toolbarButtons: undefined,
  pageTitlePos: PAGE_TITLE_POS.BOTTOM,
  router: undefined,
  drawerFooter: [],
  secondaryDrawerOpened: false,
  secondaryDrawerEnabled: false,
  theme: flintTheme,
});

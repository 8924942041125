import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyle } from './DetailsWrapperHeader.style';
import { DetailsWrapperHeaderProps } from './DetailsWrapperHeader.interface';

export const DetailsWrapperHeader = ({
  title,
  subTitle,
  LeftComponent,
  children,
}: DetailsWrapperHeaderProps) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Fragment>
      <Grid container justify="space-between">
        <div className={classes.rightContainer}>
          <Typography variant="h5" className={classes.headerTitle}>
            {t(title)}
          </Typography>
          {subTitle && (
            <Typography className={classes.headerSubTitle}>
              {t(subTitle)}
            </Typography>
          )}
        </div>
        {LeftComponent && <LeftComponent />}
      </Grid>
      {children || null}
    </Fragment>
  );
};

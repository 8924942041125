import { authenticateUser, getAuthToken, getRedirectUrl } from '../auth';

export const customHttpFetch = async (uri, options, throwAuthError = false) => {
  console.log(options, uri);
  const intialRequest = fetch(uri, options);

  const checkAuthError = async (response) => {
    const posipoleAuthErrMsgs = [
      'رمز غير صحيح',
      'INVALID TOKEN',
      'Not Authorized!',
    ];

    const responseString = JSON.stringify(response);

    const hasAuthErr = posipoleAuthErrMsgs.some((error) =>
      responseString.includes(error),
    );

    console.log('hasAuthError', hasAuthErr);

    if (hasAuthErr) {
      if (throwAuthError) {
        console.log('Redirect user to auth page');
        location.href = getRedirectUrl();
      } else {
        try {
          const user = await authenticateUser();
          console.log('re-auth', user);
          const token = await getAuthToken();
          console.log('get auth token', token);
          options.headers.authorization = `Bearer ${token}`;

          return customHttpFetch(uri, options, true);
        } catch (error) {
          error?.authorize();
          console.error(error);
        }
      }
    }
  };

  return intialRequest
    .then(async (response) => {
      return response.json();
    })
    .then(async (json) => {
      await checkAuthError(json);

      // If there were no errors in the initialRequest, we need to repackage the promise and return it as the final result.
      const jsonRes = () =>
        new Promise(function (resolve) {
          resolve(json);
        });

      const textRes = () =>
        new Promise(function (resolve) {
          resolve(JSON.stringify(json));
        });

      return {
        ok: true,
        jsonRes,
        text: textRes,
      };
    })
    .catch(async (error) => {
      await checkAuthError(error);
    });
};

import { authenticateUser } from '../../auth';
import { AuthConfig } from '../../auth/AuthConfig';

export const authenticateUserAsync = (loggedIn: boolean | undefined) => (
  dispatch,
) => {
  if (!AuthConfig.config || !Object.keys(AuthConfig.config).length) {
    console.error(
      '[authenticateUser]',
      'Authentication config does not settled yet',
    );
    return;
  }
  dispatch({
    reducer: 'flint.auth',
    type: 'authenticating',
    payload: true,
  });
  authenticateUser()
    .then((data) => {
      console.log('[authenticateUser]', data.user);
      dispatch({
        reducer: 'flint.auth',
        type: 'user',
        payload: data.user,
      });
    })
    .catch((error) => {
      const { authorize } = error;
      console.log('[AUTHORIZE]', authorize);
      console.log('[AUTHORIZE ERROR]', error);
      if (authorize && [undefined, true].includes(loggedIn)) {
        authorize();
      }
      dispatch({
        reducer: 'flint.auth',
        type: 'user',
        payload: null,
      });
    })
    .finally(() => {
      dispatch({
        reducer: 'flint.auth',
        type: 'authenticating',
        payload: false,
      });
    });
};

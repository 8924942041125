import { makeStyles, createStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#005f96',
      borderRadius: 10,
      padding: theme.spacing(0, 23 / 8),
    },
    item: {
      textAlign: 'center',
      height: theme.spacing(64 / 8),
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    title: {
      color: 'white',
      fontSize: theme.spacing(12 / 8),
      fontWeight: 'bold',
      lineHeight: 1,
    },
    subTitle: {
      fontSize: theme.spacing(12 / 8),
      fontWeight: 'bold',
      color: '#bb78ed',
    },
    headerTitle: {
      fontSize: theme.spacing(17 / 8),
      fontWeight: 'bold',
      color: '#252527',
      marginBottom: 0,
    },
    headerBackTitle: {
      fontSize: theme.spacing(14 / 8),
      color: '#252527',
      display: 'flex',
      marginBottom: 0,
      cursor: 'pointer',
      alignItems: 'center',
    },
    headerBackIcon: {
      width: 18,
      height: 18,
      color: 'red',
      marginLeft: theme.spacing(10 / 8),
    },
    hide: {
      display: 'none',
    },
    headerSubTitle: {
      margin: theme.spacing(1, 0, 4),
      lineHeight: 1.38,
      fontSize: theme.spacing(13 / 8),
      color: '#a6a5a9',
      fontWeight: 400,
    },
    rightContainer: {},
  }),
);

import { ThemeOptions } from '@material-ui/core';

export interface ILink {
  isDivider?: boolean;
  title?: string;
  link?: string;
  IconComponent?: any;
  component?: any;
  isCurrent?: boolean;
  isActive?: boolean;
  root?: boolean;
  subItems?: ILink[];
  hasArrow?: boolean;
  onClick?: () => void | Promise<any>;
  hide?: boolean;
}

export interface IToolbarButtons {
  name: string;
  Icon: any;
  props?: any;
  hide?: boolean;
  onClick?: () => void;
}

export type ILinkItem = ILink | React.FunctionComponent<any>;

export enum PAGE_TITLE_POS {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}

export interface ILayoutState {
  secondaryDrawerEnabled?: boolean;
  secondaryDrawerOpened?: boolean;
  drawerToolbar: {
    LogoIcon?: any;
    FullLogo?: any;
    LogoComponent?: any;
  };
  pageTitle?: string;
  theme?: ThemeOptions;
  pageSubTitle?: string;
  pageTitlePos: PAGE_TITLE_POS,
  isDrawerOpen: boolean;
  links: ILink[];
  hasDetailsWrapper: {};
  isDetailsWrapperOpen: boolean;
  toolbarAdditional?: any;
  hasToolbarIcons?: boolean;
  toolbarButtons?: Array<IToolbarButtons>;
  router?: {
    pathname: string
  };
  drawerFooter?: {
    links: Array<{ text: string; url: string }>;
  };
}

import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';

interface IProps {
  loading?: boolean;
}

const useStyle = makeStyles(() =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      backgroundColor: '#f4f4f4f2',
      top: 0,
      right: 0,
      height: '100%',
      width: '100%',
      zIndex: 9,
    },
    hide: {
      display: 'none',
    },
  }),
);

export const LoadingProgress: FunctionComponent<IProps> = ({
  loading,
}: IProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <div
      className={clsx(classes.loading, {
        [classes.hide]: !loading,
      })}
    >
      <CircularProgress />
      <Typography variant="h6">please wait</Typography>
    </div>
  );
};

LoadingProgress.defaultProps = {
  loading: false,
};

export default LoadingProgress;

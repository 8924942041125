// @ts-nocheck

import { Divider, List } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IState } from "../../store/IState";
import { ILink } from "../../store/layout";
import { isReactComponent } from "../../utils/isReactComponent";
import { DrawerItem } from "../DrawerItem";

export const DrawerMenu = () => {
  const { auth, layout } = useSelector((state: IState) => state.flint);

  const { user } = auth;
  const { links, isDrawerOpen } = layout;

  const states = {
    guest: Boolean(!user),
    auth: Boolean(user),
    drawerOpened: isDrawerOpen,
    drawerClosed: !isDrawerOpen,
  };

  const checkIfVisible = (value) => {
    if (value) {
      if (typeof value === 'string') {
        value = [value]
      }

      if (Array.isArray(value)) {
        return value.every((state) => states[state])
      }
    }

    return true
  };

  const filteredLinks = links.filter(({ hideOn, showOn }) => checkIfVisible(hideOn) && checkIfVisible(showOn));

  return (
    <List component="nav" disablePadding>
      {filteredLinks.map((Platform: ILink, index) => {
        if (isReactComponent(Platform)) {
          return <Platform key={index} />;
        }
        if (Platform.isDivider) {
          return <Divider key={index} />;
        }
        if (Platform.component) {
          return <Platform.component key={index} />;
        }

        return <DrawerItem platform={Platform} key={index} />;
      })}
    </List>
  );
};

import React, { useEffect, useState } from 'react';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AuthConfig } from '../../auth/AuthConfig';
import { FlintProviderProps } from './FlintProvider.types';
import { IState } from '../../store/IState';
import RightToLeft from '../RightToLeft/RightToLeft';
import { AppProgress } from '../AppProgress';

export const FlintWrapper = ({
  drawer,
  links,
  children,
  config,
  router,
  theme: platformTheme,
  pageTitlePos,
}: FlintProviderProps) => {
  const dispatch = useDispatch();
  const { layout } = useSelector((state: IState) => state.flint);
  // Theme
  const fullTheme = { ...layout.theme, ...(platformTheme || {}) };
  const theme = createMuiTheme(fullTheme);

  const [flintTheme, setFlintTheme] = useState(theme);

  // change theme on change reducer changes
  useEffect(() => {
    const fullTheme = { ...(platformTheme || {}), ...layout.theme };
    const theme = createMuiTheme(fullTheme);
    setFlintTheme(theme);
  }, [layout.theme]);

  const [appIsReady, setAppIsReady] = useState(false);
  // initialize layout props
  useEffect(() => {
    const promises = [];
    // set drawer toolbar logo
    if (drawer && drawer.toolbar) {
      promises.push(
        dispatch({
          reducer: 'flint.layout',
          type: 'drawerToolbar',
          payload: {
            LogoIcon: drawer.toolbar.LogoIcon,
            FullLogo: drawer.toolbar.FullLogo,
          },
        }),
      );
    }
    // set map config
    if (config && config.map && config.map.key) {
      promises.push(
        dispatch({
          reducer: 'flint.map',
          type: 'googleMapKey',
          payload: config.map.key,
        }),
      );
    }
    // set up links
    if (links) {
      promises.push(
        dispatch({
          reducer: 'flint.layout',
          type: 'links',
          payload: links,
        }),
      );
    }
    // set up router
    if (router) {
      promises.push(
        dispatch({
          reducer: 'flint.layout',
          type: 'router',
          payload: router,
        }),
      );
    }
    // set page title position
    if (pageTitlePos) {
      promises.push(
        dispatch({
          reducer: 'flint.layout',
          type: 'pageTitlePos',
          payload: pageTitlePos,
        }),
      );
    }
    // set theme
    if (theme) {
      promises.push(
        dispatch({
          reducer: 'flint.layout',
          type: 'theme',
          payload: fullTheme,
        }),
      );
    }
    // AuthConfig
    if (config && config.auth && Object.keys(config.auth).length) {
      console.log(`[SETUP AUTH CONFIG]`);
      promises.push(AuthConfig.setupConfig(config.auth));
    }
    Promise.all(promises)
      .then(() => {
        setAppIsReady(true);
      })
      .catch(console.error);
  }, []);

  if (!appIsReady || !children) return <AppProgress loading />;

  return (
    <div className="flint__container">
      <CssBaseline />
      <RightToLeft>
        <ThemeProvider theme={flintTheme}>{children}</ThemeProvider>
      </RightToLeft>
    </div>
  );
};

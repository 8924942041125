import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

export interface ICircledIcon {
  Icon: any;
  bgColor?: string;
  elevated?: boolean;
  style?: any;
  color?: string;
}
const useStyle = makeStyles((theme) =>
  createStyles({
    circledIconRoot: {
      boxShadow: theme.shadows[1],
      height: 45,
      width: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
    },
    icon: {},
  }),
);
export const ToolbarCircledIcon = ({
  Icon,
  bgColor,
  elevated,
  style,
  color,
}: ICircledIcon) => {
  const classes = useStyle();
  return (
    <div
      className={classes.circledIconRoot}
      style={{
        backgroundColor: bgColor,
        marginRight: 25,
        [elevated ? '' : 'boxShadow']: elevated ? '' : 'none',
        ...style,
      }}
    >
      <Icon className={classes.icon} fill={color} />
    </div>
  );
};

ToolbarCircledIcon.defaultProps = {
  bgColor: 'white',
  elevated: true,
  color: '',
};

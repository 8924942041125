// import { IRootReducerState } from '@/redux/store.interface'
import { useGoogleMaps } from '../../hooks';
import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  ClickAwayListener,
  ClickAwayListenerProps,
} from '@material-ui/core';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { ReactComponent as FilterIcon } from '../../assets/images/icons/filter.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search_icon.svg';
import { GooglePlacesInputProps } from './GooglePlacesInput.interface';
import { useStyle } from './GooglePlacesInput.style';
import { debounce } from '../../utils';
import { IState } from '../../store/IState';


export const GooglePlacesInput = ({
  hideInput,
  toggleInput,
}: GooglePlacesInputProps) => {
  const classes = useStyle();
  const inputRef = useRef();
  const resultsRef = useRef();
  const dispatch = useDispatch();
  const { inputChange } = useSelector((state: IState) => state.flint.map);
  const { secondaryDrawerEnabled } = useSelector((state: IState) => state.flint.layout);
  const [sessionToken, setSessionToken] = useState(null);
  const [mouseEvent, setMouseEvent] = useState<
    ClickAwayListenerProps.mouseEvent
  >(false);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [geocoder, setGeocoder] = useState(null);
  const { t } = useTranslation();
  const { maps } = useGoogleMaps();

  // methods
  // display displaySuggestions method
  const displaySuggestions = function (predictions, status) {
    if (status !== maps.places.PlacesServiceStatus.OK) {
      return;
    }
    const results_html = predictions.map(
      ({ place_id, description }) =>
        `<li class="autocomplete-item" data-type="place" data-place-id=${place_id}><span class="autocomplete-icon icon-localities"></span><span class="autocomplete-text">${description}</span></li>`,
    );
    if (resultsRef && resultsRef.current) {
      resultsRef.current.innerHTML = results_html.join('');
      resultsRef.current.style.display = 'block';
    }
    const autocomplete_items = resultsRef.current.querySelectorAll(
      '.autocomplete-item',
    );
    for (const autocomplete_item of autocomplete_items) {
      autocomplete_item.addEventListener('click', async function () {
        const selected_text = this.querySelector('.autocomplete-text')
          .textContent;
        const placeId = this.getAttribute('data-place-id');

        geocoder.geocode({ placeId, region: 'SA' }, (results, status) => {
          if (status !== 'OK') {
            console.log(
              `Geocode was not successful for the following reason: ${status}`,
            );
            return;
          }
          if (inputChange) {
            inputChange(results[0]);
          }
          inputRef.current.value = selected_text;
          resultsRef.current.style.display = 'none';
        });
      });
    }
  };
  const inputListener = debounce(function onInputChange() {
    const { value } = this;
    value.replace('"', '\\"').replace(/^\s+|\s+$/g, '');
    if (value !== '' && value.length > 2) {
      autocompleteService.getPlacePredictions(
        {
          componentRestrictions: {
            country: 'SA',
          },
          input: value,
          sessionToken,
        },
        displaySuggestions,
      );
    } else {
      resultsRef.current.innerHTML = '';
      resultsRef.current.style.display = 'none';
    }
  }, 400);

  // create a seesion token when focus on input
  const inputFocusListener = () => {
    const localSessionToken = new maps.places.AutocompleteSessionToken();
    setSessionToken(localSessionToken);
  };

  const createSessionToken = () => {
    // const input = ReactDOM.findDOMNode(inputRef.current)
    inputRef.current.addEventListener('focus', inputFocusListener);
  };
  // init google services
  const initGoogleServices = () => {
    const service = new maps.places.AutocompleteService();
    const gcoder = new maps.Geocoder();
    createSessionToken();
    setGeocoder(gcoder);
    setAutocompleteService(service);
  };

  // set up input event listeners
  const setInputEventListeners = () => {
    const input = ReactDOM.findDOMNode(inputRef.current);
    input.addEventListener('input', inputListener);
  };

  const openFilterHanlder = (e) => {
    e.stopPropagation();
    dispatch({
      reducer: 'flint.layout',
      type: 'secondaryDrawerOpened',
      payload: true,
    })
  };

  const toggleInputHanlder = (status: boolean) => {
    // if input is showen
    if (status) {
      setMouseEvent('onClick');
    } else {
      setMouseEvent(false);
    }

    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    if (toggleInput) {
      const finalStatus =
        inputRef && inputRef.current && inputRef.current.value ? true : status;
      toggleInput(finalStatus);
    }
  };

  const onClickAwayHanlder = () => {
    if (inputRef.current.value) {
      return;
    }
    toggleInputHanlder(false);
  };

  // effect

  // set up google services
  useEffect(() => {
    if (maps) {
      initGoogleServices();
    }
  }, [maps]);
  // set input event listeners
  useEffect(() => {
    // when input value changes
    setInputEventListeners();
    // remove input event listeners
    return () => {
      const input = ReactDOM.findDOMNode(inputRef.current);
      input.removeEventListener('input', inputListener);
      input.removeEventListener('input', inputFocusListener);
    };
  }, [geocoder, autocompleteService, maps, inputChange]);

  // component helpers
  const prependIcon = (
    <InputAdornment
      position="end"
      onClick={() => toggleInputHanlder(true)}
      style={{ marginLeft: 15 }}
    >
      <SearchIcon />
    </InputAdornment>
  );

  const appendIcon = (
    <InputAdornment
      position="end"
      disableTypography
      className={clsx({
        [classes.hide]: hideInput,
      })}
    >
      <IconButton onClick={openFilterHanlder}>
        <FilterIcon />
      </IconButton>
    </InputAdornment>
  );

  return (
    <ClickAwayListener onClickAway={onClickAwayHanlder} mouseEvent={mouseEvent}>
      <div className={classes.root}>
        <OutlinedInput
          className={clsx(classes.outlineInput, {
            [classes.active]: !hideInput,
          })}
          placeholder={t('enter a city, neighborhood, or landmark to search')}
          classes={{
            notchedOutline: classes.notchedOutline,
            input: clsx(classes.input, {
              [classes.hideInput]: hideInput,
            }),
          }}
          inputRef={inputRef}
          autoFocus
          startAdornment={prependIcon}
          endAdornment={secondaryDrawerEnabled ? appendIcon : null}
        />
        <ul ref={resultsRef} className={classes.results} />
      </div>
    </ClickAwayListener>
  );
};

// props validation
GooglePlacesInput.propTypes = {
  hideInput: propTypes.bool,
  toggleInput: propTypes.func.isRequired,
};
// props default values
GooglePlacesInput.defaultProps = {
  hideInput: true,
};

export default GooglePlacesInput;

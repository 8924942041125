import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, createStyles, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { GooglePlacesInput } from '../GooglePlacesInput';
import { ToolbarCircledIcon } from '../ToolbarCircledIcon';
import { IState } from '../../store/IState';
import { IToolbarButtons } from '../../store';

const useStyle = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },

    iconsWrapper: {
      // display: 'flex',
      // width: '100%',
      // transition: theme.transitions.create(['width', 'display'], {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },
    hide: {
      display: 'none',
      width: 0,
      transition: theme.transitions.create(['width', 'display'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    iconButton: {
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);

let iconsTimer: NodeJS.Timeout;

export const ToolbarIcons = ({applayGoogleStyle}) => {
  const classes = useStyle();
  const [inputActive, setInputActive] = useState(false);
  const [hideIcons, setHideIcons] = useState(false);

  useEffect(() => {
    applayGoogleStyle(inputActive)
  }, [inputActive]);

  const { toolbarButtons } = useSelector((state: IState) => state.flint.layout);

  const toggleInputHandler = (status: boolean) => {
    setInputActive(status);
    if (iconsTimer) {
      clearTimeout(iconsTimer);
    }
    if (status) {
      setHideIcons(true);
    } else {
      iconsTimer = setTimeout(() => {
        setHideIcons(false);
      }, 200);
    }
  };

  const iconButtonHandler = (icon: IToolbarButtons) => {
    if (icon.onClick) {
      icon.onClick();
    }
  };

  return (
    <Grid className={clsx(classes.root, { [classes.root]: inputActive })}>
      <div
        className={clsx(classes.iconsWrapper, {
          [classes.hide]: hideIcons,
        })}
      >
        {toolbarButtons && toolbarButtons.length
          ? toolbarButtons
              .filter((icon) => icon.name !== 'search')
              .map((icon) => (
                <IconButton
                  onClick={() => iconButtonHandler(icon)}
                  key={icon.name}
                  disableRipple
                  className={classes.iconButton}
                >
                  <ToolbarCircledIcon
                    Icon={icon.Icon}
                    {...(icon.props || {})}
                  />
                </IconButton>
              ))
          : null}
      </div>
      {toolbarButtons &&
      toolbarButtons.find((icon) => icon.name === 'search') ? (
        <GooglePlacesInput
          hideInput={!inputActive}
          toggleInput={toggleInputHandler}
        />
      ) : null}
    </Grid>
  );
};

// Props validation
ToolbarIcons.propTypes = {
  hide: PropTypes.bool,
};

// Props default Values
ToolbarIcons.defaultProps = {
  hide: false,
};

import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles(theme => ({
  root: {
    borderRadius: 10,
    border: `1px solid`,
    overflow: 'auto'
  },
  rowTitle: {
    backgroundColor: theme.palette.common.white,
    borderRight: '1px solid',
    borderRightColor: '#e1deed',
  },
  cellText: {

    fontSize: 13,
    fontWeight: 'bold',
    color: theme.palette.common.black

  },
  cell: {
    borderRight: '1px solid',
    borderRightColor: '#e1deed',
    '&:last-of-type': {
      borderRight: 'none'
    }
  },
  childrenRoot: {
    borderLeft: '1px solid #ccc',
    paddingTop: 8
  },
  childTextWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    '&:last-of-type': {
      "& .table_child_text": {
      },
    },
    '&::before': {
      content: '""',
      backgroundColor: '#ccc',
      minWidth: 16,
      height: 1
    },
  },
  childText: {
    marginLeft: theme.spacing(1),
    lineHeight: 2,
  },
  levelWrapper: {
    marginTop: theme.spacing(1)
  }
}))
// @ts-nocheck
// @ts-ignore

import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Popper,
  Divider,
  MenuItem,
  MenuList,
  Typography,
  ClickAwayListener,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { ReactComponent as LogoutIcon } from '../../assets/images/icons/logout.svg';
import { ReactComponent as UserWhiteIcon } from '../../assets/images/icons/user.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/icons/settings.svg';
import { ReactComponent as UserBlackIcon } from '../../assets/images/icons/user-black.svg';
import { ReactComponent as LoginProfileIcon } from '../../assets/images/icons/login_user_profile_icon.svg';
import { useStyle } from './DrawerUserProfile.style';
import { IState } from '../../store/IState';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks';
import { authenticateUser, logoutUser } from '../../auth';
import clsx from 'clsx'

export const DrawerUserProfile = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { authenticating: loading, user } = useAuth();
  const { isDrawerOpen } = useSelector((state: IState) => state.flint.layout);
  const theme = useTheme();

  const dropdownTogglerRef = useRef(null);

  const onAuthenticateUser = () => {
    authenticateUser()
      .then(({ user }) => {
        dispatch({
          reducer: 'flint.auth',
          type: 'user',
          payload: user,
        });
      })
      .catch(({ authorize }) => {
        authorize();
      });
  };

  const handleToggle = () => {
    setDropdownOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (e) => {
    if (
      dropdownTogglerRef.current &&
      dropdownTogglerRef.current &&
      dropdownTogglerRef.current.contains(e.target)
    ) {
      return;
    }

    setDropdownOpen(false);
  };

  const handleListKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      setDropdownOpen(false);
    }
  };

  const prevOpen = useRef(dropdownOpen);
  useEffect(() => {
    if (prevOpen.current === true && dropdownOpen === false) {
      dropdownTogglerRef.current.focus();
    }

    prevOpen.current = dropdownOpen;
  }, [dropdownOpen]);

  const dropDownMenuItems = [
    {
      title: 'profile',
      icon: UserBlackIcon,
      onClick: () => {},
    },
    {
      title: 'settings',
      icon: SettingsIcon,
      onClick: () => {},
    },
    {
      title: 'logout',
      icon: LogoutIcon,
      onClick: logoutUser,
    },
  ];

  return (
    <div className={clsx({[classes.root]: true, [classes.center]: !isDrawerOpen})}>
      {loading ? (
        <Typography style={{ minHeight: 65 }} />
      ) : user && Object.keys(user).length ? (
        <div className={[classes.dropdownContainer]}>
          <Button
            disableElevation
            variant="contained"
            aria-haspopup="true"
            onClick={handleToggle}
            ref={dropdownTogglerRef}
            disableRipple={!isDrawerOpen}
            aria-controls={dropdownOpen ? 'menu-list-grow' : undefined}
            className={
              isDrawerOpen || (!isDrawerOpen && dropdownOpen)
                ? dropdownOpen
                  ? classes.dropdownOpenButton
                  : classes.dropdownClosedButton
                : classes.profileUserIcon
            }
          >
            {isDrawerOpen || (!isDrawerOpen && dropdownOpen) ? (
              <div className={classes.dropdownBtnContent}>
                <LoginProfileIcon
                  style={{ fill: theme.palette.primary.main }}
                />
                <Typography
                  style={{ marginRight: 10, textTransform: 'capitalize' }}
                >
                  {user.first_name} {user.last_name}
                </Typography>
                <ExpandMoreIcon style={{ marginRight: 'auto' }} />
              </div>
            ) : (
              <LoginProfileIcon style={{ fill: theme.palette.primary.main }} />
            )}
          </Button>

          <Popper
            disablePortal
            role={undefined}
            open={dropdownOpen}
            anchorEl={dropdownTogglerRef.current}
            className={`${classes.dropdownPopper} ${
              !isDrawerOpen ? classes.openDrawerDropdownPopper : ''
            }`}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                id="menu-list-grow"
                style={{ padding: 0 }}
                autoFocusItem={dropdownOpen}
                onKeyDown={handleListKeyDown}
              >
                {dropDownMenuItems.map((item, itemIndex) => (
                  <MenuItem
                    key={itemIndex}
                    onClick={(e) => {
                      handleClose(e);
                      item.onClick();
                    }}
                    className={classes.dropdownMenuItem}
                  >
                    <item.icon className={classes.dropdownMenuItemIcon} />
                    <span className={classes.dropdownMenuItemText}>
                      {t(`flint:${item.title}`)}
                    </span>
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Popper>
        </div>
      ) : isDrawerOpen ? (
        <Button
          disableElevation
          color="primary"
          variant="contained"
          onClick={onAuthenticateUser}
          className={classes.guestDropdownBtn}
        >
          <LoginProfileIcon style={{ fill: theme.palette.secondary.main }} />
          <Typography style={{ marginRight: 10, fontWeight: 'bold' }}>
            {t('flint:login')}
          </Typography>
        </Button>
      ) : (
        <Button
          disableElevation
          color="secondary"
          variant="contained"
          className={classes.profileUserIcon}
          onClick={onAuthenticateUser}
        >
          <LoginProfileIcon style={{ fill: theme.palette.primary.main }} />
        </Button>
      )}
      <Divider className={classes.divider} />
    </div>
  );
};

import { IconButton } from '@material-ui/core';
import * as React from 'react';
import { ReactComponent as ChevronLeftIcon } from '../../assets/images/icons/left_chervon.svg';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store/IState';
import { useStyle } from './DrawerDetailsIcon.style';

export const DrawerDetailsIcon = () => {
  const classes = useStyle();
  const { isDetailsWrapperOpen } = useSelector(
    (state: IState) => state.flint.layout,
  );
  const dispatch = useDispatch();

  const changeStatus = () => {
    dispatch({
      reducer: 'flint.layout',
      type: 'isDetailsWrapperOpen',
      payload: !isDetailsWrapperOpen,
    });
  };

  return (
    <IconButton
      color="default"
      disableRipple
      className={clsx(classes.root, {
        [classes.closed]: !isDetailsWrapperOpen,
      })}
      onClick={changeStatus}
    >
      <ChevronLeftIcon />
    </IconButton>
  );
};

import { AuthConfig } from './AuthConfig';

/**
 * Get userinfo.
 *
 * @return axios instance.
 */
export const getUserInfo = (options = {}) => {
  return AuthConfig.http().get('/userinfo', {
    ...options,
  });
};

/**
 * Get well known jwks.
 *
 * @return axios instance.
 */
export const getWellKnownJwks = (options = {}) => {
  return AuthConfig.http().get('/.well-known/jwks.json', {
    ...options,
  });
};

/**
 * Refresh user token.
 *
 * @return axios instance.
 */
export const refreshUserToken = (refreshToken, options = {}) => {
  const data = new FormData();
  data.append('grant_type', 'refresh_token');
  data.append('refresh_token', refreshToken);
  data.append('client_id', AuthConfig.getOne('clientId'));
  data.append('redirect_uri', AuthConfig.getOne('redirectUri'));

  return AuthConfig.http().post('/oauth2/token', data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    ...options,
  });
};

// @ts-nocheck
import React from 'react';
import {
  Box,
  Button,
  Divider,
  Drawer as MuiDrawer,
  Link,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useStyle } from './Drawer.style';
import { DrawerToolbar } from '../DrawerToolbar';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store/IState';
import DrawerIcon from '../../assets/images/icons/drawer_icons/drawer_icon.png';
import { DrawerMenu } from './DrawerMenu';
import { DrawerUserProfile } from '../DrawerUserProfile';
import { getI18n, useTranslation } from 'react-i18next';
import { flintI18n } from '../../locales';

const footerLinks = [
  { text: 'privacy policy and usage', url: '/privacy' },
  { text: 'contact us', url: `https://mail.google.com/mail/?view=cm&fs=1&to=contact@gt.com.sa`, target:"_blank" },
];

export const Drawer = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { isDrawerOpen } = useSelector((state: IState) => state.flint.layout);
  const dispatch = useDispatch();
  const updateDrawerStatus = () => {
    dispatch({
      reducer: 'flint.layout',
      type: 'isDrawerOpen',
      payload: true,
    });
  };

  return (
    <nav className={classes.root}>
      <MuiDrawer
        variant="permanent"
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
            [classes.drawerPaper]: !isDrawerOpen,
          }),
        }}
      >
        <div
          className={clsx(classes.drawerContent, {
            [classes.drawerContentClosed]: !isDrawerOpen,
          })}
        >
          <DrawerToolbar Component="a" />
          {/* User Profile */}
          <DrawerUserProfile />
          {/* Drawer Content */}
          <DrawerMenu />
        </div>
        {/* Drawer Icon */}
        {/* <Box display={isDrawerOpen ? 'none' : 'block'}>
          <div style={{ marginBottom: 80 }}>
            <Button className={classes.menuIcon} onClick={updateDrawerStatus}>
              <img src={DrawerIcon} alt="drawer icon" />
            </Button>
          </div>
        </Box> */}
        {/* End Drawer Icon */}
        {/* Footer */}
        <div
          className={clsx(classes.footer, {
            [classes.hide]: !isDrawerOpen,
          })}
        >
          <Divider />
          <ul className={classes.footerList}>
            {footerLinks.map((item) => (
              <Link
                href={item.url}
                key={item.text}
                className={classes.footerListItem}
                target={item?.target}
              >
                {t(`${`flint:${item.text}`}`)}
              </Link>
            ))}
          </ul>
          <Typography className={classes.copyright}>
            {t('flint:all rights reserved', {
              year: new Date().getFullYear(),
              platform: t('platform'),
            })}
          </Typography>
        </div>
      </MuiDrawer>
    </nav>
  );
};

import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { LEFT_LIST_WIDTH, DRAWER_WIDTH } from '../../utils/theme';

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    shiftRight: {
      marginRight: LEFT_LIST_WIDTH,
    },
    content: {
      overflow: 'auto',
      height: '100vh',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    hasToolbar: {
      height: 'calc(100vh - 83px)',
    },
    hasLayoutDrawer: {
      padding: theme.spacing(3),
      marginLeft: DRAWER_WIDTH,
    },
    drawerClosed: {
      marginLeft: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(9) + 1,
      },
    },
  }),
);

import { getWellKnownJwks } from './authApi'

export default class JwkClient {
  async getSigningKey(kid) {
    try {
      const keys = await this.getSigningKeys()

      const key = keys.find((k) => k.kid === kid)
      if (key) {
        return Promise.resolve(key)
      }
      return Promise.reject(
        `Unable to find a signing key that matches '${kid}'`
      )
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async getSigningKeys() {
    const self = this
    try {
      const keys = await this.__requestKeys()
      if (!keys || !keys.length) {
        return Promise.reject('The JWKS endpoint did not contain any keys')
      }

      const signingKeys = keys
        .filter(function (key) {
          return (
            key.use === 'sig' &&
            key.kty === 'RSA' &&
            key.kid &&
            ((key.x5c && key.x5c.length) || (key.n && key.e))
          )
        })
        .map(function (key) {
          if (key.x5c && key.x5c.length) {
            return {
              kid: key.kid,
              nbf: key.nbf,
              publicKey: self.__certToPEM(key.x5c[0]),
            }
          }
          return {
            kid: key.kid,
            nbf: key.nbf,
            rsaPublicKey: self.__rsaPublicKeyToPEM(key.n, key.e),
          }
        })

      if (!signingKeys.length) {
        return Promise.reject(
          'The JWKS endpoint did not contain any signing keys'
        )
      }
      return Promise.resolve(signingKeys)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  __certToPEM(cert) {
    cert = cert.match(/.{1,64}/g).join('\n')
    cert = `-----BEGIN CERTIFICATE-----\n${cert}\n-----END CERTIFICATE-----\n`
    return cert
  }

  __prepadSigned(hexStr) {
    const msb = hexStr[0]
    if (msb < '0' || msb > '7') {
      return `00${hexStr}`
    }
    return hexStr
  }

  __rsaPublicKeyToPEM(modulusB64, exponentB64) {
    const modulus = new Buffer(modulusB64, 'base64')
    const exponent = new Buffer(exponentB64, 'base64')
    const modulusHex = this.__prepadSigned(modulus.toString('hex'))
    const exponentHex = this.__prepadSigned(exponent.toString('hex'))
    const modlen = modulusHex.length / 2
    const explen = exponentHex.length / 2

    const encodedModlen = this.__encodeLengthHex(modlen)
    const encodedExplen = this.__encodeLengthHex(explen)
    const encodedPubkey = `30${this.__encodeLengthHex(
      modlen + explen + encodedModlen.length / 2 + encodedExplen.length / 2 + 2
    )}02${encodedModlen}${modulusHex}02${encodedExplen}${exponentHex}`

    const der = new Buffer(encodedPubkey, 'hex').toString('base64')

    let pem = '-----BEGIN RSA PUBLIC KEY-----\n'
    pem += `${der.match(/.{1,64}/g).join('\n')}`
    pem += '\n-----END RSA PUBLIC KEY-----\n'
    return pem
  }

  async __requestKeys() {
    try {
      const { data } = await getWellKnownJwks()

      const keys = Object.entries(data.keys || []).reduce(
        (keys, [key, value]) => {
          keys[key] = value

          return keys
        },
        []
      )

      return Promise.resolve(keys)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  __toHex(number) {
    const nstr = number.toString(16)
    if (nstr.length % 2) {
      return `0${nstr}`
    }
    return nstr
  }

  __encodeLengthHex(n) {
    if (n <= 127) {
      return this.__toHex(n)
    }
    const nHex = this.__toHex(n)
    const lengthOfLengthByte = 128 + nHex.length / 2
    return this.__toHex(lengthOfLengthByte) + nHex
  }
}

import axios from 'axios';
import { authInterceptor } from './utils';

export interface IAuthConfig {
  clientId: string;
  scopes: string | Array<string>;
  redirectUri: string;
  tokenPath: string;
  domain: string;
  authorizePath: string;
}

export class AuthConfig {
  static config: IAuthConfig;
  static setupConfig(config: IAuthConfig) {
    return new Promise((resolve) => {
      AuthConfig.config = config;
      resolve();
    });
  }
  static getOne<T extends keyof IAuthConfig>(key: T): IAuthConfig[T] {
    if (!AuthConfig.hasKey(key)) {
      console.error(`The ${key} does not exist in AuthConfig`);
      return ''
    }
    return AuthConfig.config[key];
  }
  static getScopes(): Array<string> {
    const scopes = AuthConfig.getOne('scopes');
    if (scopes && Array.isArray(scopes)) {
      return scopes;
    }
    return (scopes as string).split(',');
  }
  static http() {
    const instance = axios.create({
      baseURL: AuthConfig.getOne('domain'),
    });

    instance.interceptors.request.use(authInterceptor);

    return instance;
  }

  static hasKey<T extends keyof IAuthConfig>(key: T): boolean {
    if (!AuthConfig.config) return false
    return  !!AuthConfig.config[key];
  }
}

import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // backgroundColor: '#eeeef5',
    borderRadius: 10,
  },
  firstCol: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    border: 'solid 1px #e8e6f4',
  },
  rowsWrapper: { flexGrow: 1, paddingRight: theme.spacing(4) },
}));

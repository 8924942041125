import React, { useState, useEffect, Fragment } from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Collapse,
  List,
} from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/icons/left_arrow.svg';
import { useStyle } from './DrawerItem.style';
import { ILink, IState } from '../../store';
import { useSelector } from 'react-redux';

export const DrawerItem = ({ platform }: { platform: ILink }) => {
  const { isDrawerOpen, router } = useSelector((state: IState) => state.flint.layout);
  const classes = useStyle({
    classes: { hideRootText: 'hide_menu_text' },
  });


  const [isOpened, setIsOpened] = useState(false);

  const onClickRootMenuItem = () => {
    if (platform.onClick && typeof platform.onClick === 'function') {
      platform.onClick();
    }

    setIsOpened(!isOpened);
  };

  useEffect(() => {
    const opened = (platform.subItems || []).some(
      ({ link }) => router && router.pathname === link,
    );
    setIsOpened(opened);
  }, [router]);

  const MenuItemRoot = (
    <ListItem
      button
      key={platform.title}
      onClick={onClickRootMenuItem}
      className={clsx(classes.item, {
        [classes.rootActive]:
          (platform.hasArrow && isOpened) || router && router.pathname === platform.link,
      })}
    >
      <ListItemIcon classes={{ root: classes.sideIcon }}>
        <platform.IconComponent
          className={clsx(classes.icon, {
            [classes.activeIcon]: isOpened || router && router.pathname === platform.link,
          })}
        />
      </ListItemIcon>
      <ListItemText
        classes={{
          primary: clsx({
            [classes.subItem]: !platform.hasArrow,
            [classes.activeLink]: isOpened || router && router.pathname === platform.link,
            [classes.rootText]: platform.hasArrow,
            [classes.hideRootText]: !isDrawerOpen,
          }),
        }}
        primary={platform.title}
      />

      {platform.hasArrow && isDrawerOpen ? (
        <ListItemSecondaryAction>
          <LeftArrowIcon />
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );

  const MenuItemChildren = isOpened ? (
    <Collapse
      in
      timeout="auto"
      unmountOnExit
      className={clsx({
        [classes.hide]: !isDrawerOpen,
      })}
    >
      <List component="div" disablePadding className={classes.nested}>
        {(platform.subItems || []).map((item, index) => {
          if (item.hide) {
            return null;
          }
          return <DrawerItem platform={item} key={index} />;
        })}
      </List>
    </Collapse>
  ) : null;

  return (
    <Fragment>
      {MenuItemRoot}
      {MenuItemChildren}
    </Fragment>
  );
};

export default DrawerItem;

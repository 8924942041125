import { setContext } from '@apollo/client/link/context';
import { getAuthToken } from '../../..';

export const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getAuthToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    console.error(`[authLink]`, error);
  }
});

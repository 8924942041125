import { makeStyles, createStyles } from '@material-ui/core'

export const useStyle = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      position: 'relative',
      margin: 'auto',
    },
    results: {
      margin: '0 auto',
      right: 0,
      left: 0,
      position: 'absolute',
      display: 'none',
      backgroundColor: 'white',
      padding: 0,
      listStyleType: 'none',
      border: '1px solid #d2d2d2',
      borderTop: 0,
      boxSizing: 'border-box',
      zIndex: 2,
      '&::after': {
        content: '""',
        padding: '1px 1px 1px 0',
        height: 18,
        boxSizing: 'border-box',
        textAlign: 'right',
        display: 'block',
        backgroundImage:
          'url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)',
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '120px 14px',
      },
    },
    outlineInput: {
      transition: 'all .2s ease-in-out',
      borderRadius: 10,
      backgroundColor: 'transparent',
      width: 0,
      marginRight: 20,
    },
    notchedOutline: {
      border: 'none',
    },
    active: {
      width: 400,
      backgroundColor: '#f6f5fa',
    },
    input: {},
    hide: { display: 'none' },
    hideInput: {
      width: 0
    },
  })
)

export interface IGqConfig {
  url: string;
}

export class GqConfig {
  static config: IGqConfig;
  static setupConfig(config: IGqConfig) {
    return new Promise((resolve) => {
      GqConfig.config = config;
      resolve();
    });
  }
  static getOne<T extends keyof IGqConfig>(key: T): IGqConfig[T] {
    if (!GqConfig.hasKey(key)) {
      console.error(`The ${key} does not exist in IGqConfig`);
      return '';
    }
    return GqConfig.config[key];
  }

  static hasKey<T extends keyof IGqConfig>(key: T): boolean {
    if (!GqConfig.config) return false;
    return !!GqConfig.config[key];
  }
}

import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { Drawer, Typography, IconButton, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as CancelIcon } from '../../assets/images/icons/cancel.svg';
import { useStyle } from './SecondaryDrawer.style';
import { SecondaryDrawerProps } from './SecondaryDrawer.interface';
import { IState } from '../../store/IState';

export const SecondaryDrawer = ({
  header,
  button,
  children,
  onClose,
  hideButton,
}: SecondaryDrawerProps) => {
  const classes = useStyle();
  const {
    flint: { layout },
  } = useSelector((state: IState) => state);
  const { t } = useTranslation();
  const { secondaryDrawerOpened } = layout;

  const dispatch = useDispatch();

  const onClickHandler = async () => {
    if (button && button.onClick && !button.disabled) {
      button.onClick(closeDrawer);
    }
  };

  const closeDrawer = () => {
    dispatch({
      reducer: 'flint.layout',
      type: 'secondaryDrawerOpened',
      payload: false,
    });

    if (onClose) {
      onClose();
    }
  };

  return (
    <Drawer
      open={secondaryDrawerOpened}
      onClose={closeDrawer}
      variant="temporary"
      anchor="right"
      disableEnforceFocus
      classes={{
        paper: classes.paper,
      }}
    >
      <div style={{ direction: 'rtl' }}>
        <div className={classes.header}>
          <Typography className={classes.heading}>
            {header && header.title && t(header.title)}
          </Typography>
          <IconButton onClick={closeDrawer}>
            <CancelIcon />
          </IconButton>
        </div>
        <Typography className={classes.description}>
          {header && header.subTitle && t(header.subTitle)}
        </Typography>
        <div className={classes.content}>
          {children}
          {hideButton ? null : (
            <Button
              fullWidth
              variant="contained"
              type="submit"
              color={button && button.color ? button.color : 'primary'}
              disabled={button && button.disabled}
              className={classes.button}
              disableElevation
              onClick={onClickHandler}
            >
              {button && button.text && t(button.text)}
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default SecondaryDrawer;

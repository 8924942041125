import React from 'react';
import { getGraphqlClient } from '../../graphql';
import { ApolloProvider } from '@apollo/client';

export interface IFlintAppWrapperProps {
  children?: any
}

export const FlintAppWrapper = ({ children }: IFlintAppWrapperProps) => {
  return (
    <ApolloProvider client={getGraphqlClient()}>
      {children}
    </ApolloProvider>
  );
};
